// import deliveryType from '@/data/source/local/json/deliveryType.json'
import {
  ref, onMounted, getCurrentInstance, reactive, watch
} from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import {
  courierScheduleUseCase
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { useRoute } from 'vue-router'
import QrcodeVue from 'qrcode.vue'
import print from 'vue3-print-nb'
import { isDate } from 'lodash'

export default {
  name: 'CourierSchedule',
  props: {
    dataEdit: {
      default: () => null
    }
  },
  directives: {
    print
  },
  components: {
    QrcodeVue,
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    // eslint-disable-next-line object-curly-newline
    const { $confirm, $toast, $swal, $strInd } = app!.appContext.config.globalProperties

    const dataEdit = ref(props.dataEdit)
    const route = useRoute()
    const store = useStore()
    const submitted = ref(false)
    const lastIndex = ref(null)
    const currentId = ref(null)
    const showForm = ref(false)
    const prevDateStart = ref('')
    const prevDateEnd = ref('')
    const currentDateStart = ref('')
    const currentDateEnd = ref('')
    const listSchedule = ref([]) as any
    const scheduleIsExist = ref(false)
    const scheduleIsIntersect = ref(false)
    // const agentData = reactive({
    //   AgenId: null,
    //   Alamat: null,
    //   KotaId: null,
    //   NamaAgen: null,
    //   NamaKota: null,
    // })
    const dataForm = reactive({
      startDate: null,
      endDate: null,
    }) as any

    const rules = {
      startDate: {
        required: helpers.withMessage('Waktu/Jam Mulai harus diisi', required)
      },
      endDate: {
        required: helpers.withMessage('Waktu/Jam Berakhir harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const getMinDate = () => {
      if (dataForm.startDate) {
        if (dataForm.startDate.toString().includes('-')) {
          const arrDate = dataForm.startDate.split('-')
          return new Date(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`)
        }
        return new Date(moment(dataForm.startDate).format('YYYY-MM-DD'))
      }
      return new Date('2021-01-01')
    }

    const checkDate = (startDate: any, endDate: any) => {
      if (startDate) {
        if (startDate.toString().includes('-')) {
          const arrDate = startDate.split('-')
          startDate = new Date(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`)
        }
        if (endDate) {
          if (endDate.toString().includes('-')) {
            const arrDate = endDate.split('-')
            endDate = new Date(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`)
          }
          if (startDate - endDate > 0) {
            dataForm.endDate = null
          }
        }
      } else {
        dataForm.endDate = null
      }
    }
    const loadAllSchedule = async () => {
      store.dispatch('showLoading')
      const response = await courierScheduleUseCase.getAll({
        custom: `MsAgenId eq ${store.getters['appActiveUser/getAgent']?.id}&$orderby=DateStart asc`
      })
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        listSchedule.value = response.result.map((x: any) => {
          if (prevDateStart.value !== null && prevDateEnd.value !== null) {
            if ((prevDateStart.value <= x.DateStart && prevDateEnd.value >= x.DateStart) || (prevDateStart.value <= x.DateEnd && prevDateEnd.value >= x.DateEnd)) {
              x.isIntersect = true
              return x
            }
          }
          prevDateStart.value = x.DateStart
          prevDateEnd.value = x.DateEnd
          x.isIntersect = false
          return x
          // }
        })
      }
      console.log('data', listSchedule.value)
      store.dispatch('hideLoading')
    }

    const deleteSchedule = async (item: any) => {
      const { Id } = item
      $confirm.require({
        header: 'Jadwal Kurir',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await courierScheduleUseCase.deleteData(Id)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
            })
          }
          loadAllSchedule()
          store.dispatch('hideLoading')
        }
      })
    }

    const toggleEditSchedule = async (index: any, item: any, state: boolean) => {
      const classname = 'hide-form'
      const displayField = document.getElementById(`static-${index}`)
      const formField = document.getElementById(`form-${index}`)
      showForm.value = false
      if (state === false) {
        if (displayField != null && formField != null) {
          if (displayField.classList.contains(classname)) {
            displayField.classList.remove(classname)
            formField.classList.add(classname)
            dataForm.startDate = null
            dataForm.endDate = null
            currentId.value = null
          }
        }
      } else {
        console.log('data', item)
        currentId.value = item.Id
        if (lastIndex.value != null) {
          const lastDisplayField = document.getElementById(`static-${lastIndex.value}`)
          const lastFormField = document.getElementById(`form-${lastIndex.value}`)
          console.log('item', [lastDisplayField, lastFormField])
          if (lastDisplayField != null && lastFormField != null) {
            if (lastDisplayField.classList.contains(classname)) {
              lastDisplayField.classList.remove(classname)
              lastFormField.classList.add(classname)
            }
          }
        }
        if (displayField != null && formField != null) {
          if (displayField.classList.contains(classname)) {
            displayField.classList.remove(classname)
            formField.classList.add(classname)
          } else {
            displayField.classList.add(classname)
            formField.classList.remove(classname)
          }
          // eslint-disable-next-line no-use-before-define
          dataForm.startDate = timeFormatter(item.DateStart)
          currentDateStart.value = item.DateStart
          console.log('start', currentDateStart.value)
          // eslint-disable-next-line no-use-before-define
          dataForm.endDate = timeFormatter(item.DateEnd)
          currentDateEnd.value = item.DateEnd
          console.log('end', currentDateEnd.value)
          lastIndex.value = index
        }
      }
    }

    const showFormAction = async (state: boolean) => {
      dataForm.startDate = null
      dataForm.endDate = null
      showForm.value = state
      if (state) {
        const classname = 'hide-form'
        if (lastIndex.value != null) {
          const lastDisplayField = document.getElementById(`static-${lastIndex.value}`)
          const lastFormField = document.getElementById(`form-${lastIndex.value}`)
          console.log('item', [lastDisplayField, lastFormField])
          if (lastDisplayField != null && lastFormField != null) {
            if (lastDisplayField.classList.contains(classname)) {
              lastDisplayField.classList.remove(classname)
              lastFormField.classList.add(classname)
            }
          }
        }
      }
    }
    const proccessSubmit = async () => {
      store.dispatch('showLoading')
      const checkStart = !!(typeof dataForm.startDate === 'string')
      const checkEnd = !!(typeof dataForm.endDate === 'string')
      // eslint-disable-next-line no-use-before-define
      const startDate = !checkStart ? timeToISOString(dataForm.startDate) : currentDateStart.value
      // eslint-disable-next-line no-use-before-define
      const endDate = !checkEnd ? timeToISOString(dataForm.endDate) : currentDateEnd.value
      const data = {
        DateStart: startDate,
        DateEnd: endDate,
        MsAgenId: store.getters['appActiveUser/getAgent']?.id
      }
      if (startDate < endDate) {
        // eslint-disable-next-line no-use-before-define
        checkIntersectAndDuplicateSchedule(startDate, endDate, currentId.value)
        console.log('isExist', scheduleIsExist)
        console.log('isIntersect', scheduleIsIntersect)
        if (!scheduleIsExist.value) {
          if (scheduleIsIntersect.value) {
            $swal.fire({
              text: 'Jadwal yang Anda buat berada diantara jadwal yang sudah ada. Apakah Anda yakin untuk menambahkan jadwal tersebut?',
              title: 'Jadwal Sudah Ada!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#ff9800',
              cancelButtonColor: '#F44336',
              allowOutsideClick: false,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Kembali',
            }).then(async (result: any) => {
              // console.log('resultcompo', result)
              if (result.isConfirmed) {
                // eslint-disable-next-line no-use-before-define
                innerProsesSubmit(data)
              }
            })
          } else {
            // eslint-disable-next-line no-use-before-define
            innerProsesSubmit(data)
          }
        } else {
          $toast.add({
            severity: 'error', detail: 'Jadwal sudah ada', group: 'bc', life: 3000
          })
        }
      } else {
        $toast.add({
          severity: 'error', detail: 'Jam tidak valid', group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }

    const innerProsesSubmit = async (data: any) => {
      const response = await courierScheduleUseCase.submitData(lastIndex.value === null ? null : currentId.value, data)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        showForm.value = false
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
      }
      dataForm.startDate = null
      dataForm.endDate = null
      currentId.value = null
      loadAllSchedule()
    }

    onMounted(() => {
      loadAllSchedule()
    })
    // eslint-disable-next-line arrow-body-style
    const timeFormatter = (item: any) => {
      // console.log('test', item)
      return moment(item).format('HH:mm:ss')
    }

    const timeToISOString = (data: any) => {
      const tzo = -data.getTimezoneOffset()
      const dif = tzo >= 0 ? '+' : '-'
      const pad = (num: any) => (num < 10 ? '0' : '') + num

      return `2022-01-01T${pad(data.getHours())}:${pad(data.getMinutes())}:${pad(data.getSeconds())}${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`
    }

    watch(() => dataForm.startDate, (val: any) => {
      checkDate(val, dataForm.endDate)
    })
    watch(() => dataForm.endDate, (val: any) => {
      checkDate(dataForm.startDate, val)
    })

    const checkIntersectAndDuplicateSchedule = (start: any, end: any, id: any) => {
      scheduleIsIntersect.value = false
      scheduleIsExist.value = false
      console.log('input', [start, end])
      listSchedule.value.forEach((el: any) => {
        console.log('data', [el.DateStart, el.DateEnd])
        if (id !== el.Id) {
          if ((el.DateStart <= start && el.DateEnd >= start) || (el.DateStart <= end && el.DateEnd >= end)) {
            console.log('aaa')
            scheduleIsIntersect.value = true
          }
          if (el.DateStart === start && el.DateEnd === end) {
            console.log('bbb')
            scheduleIsExist.value = true
          }
        }
      })
    }

    return {
      moment,
      dataForm,
      v$,
      route,
      submitted,
      getMinDate,
      submitData,
      deleteSchedule,
      isDate,
      showForm,
      listSchedule,
      timeFormatter,
      toggleEditSchedule,
      showFormAction
    }
  }
}
